
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { AdiantamentoCarteiraService } from '@/core/services/financeiro';
import { AdiantamentoCarteira } from '@/core/models/financeiro';
 
@Component 
export default class ListaConsumo extends mixins(Vue,ListPage) {
  service =  new AdiantamentoCarteiraService();
  item = new AdiantamentoCarteira();
 
  loading: boolean = false;
  dialogCadastro: boolean = false; 

  titulo: string = 'Tabelas Adiantamento da Carteira';
  subTitulo: string = 'Consumo de Água cadastrados no Sistema';

  options: any = {
    itemsPerPage: 15
  };  

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Empresa', value: 'empresaSolicitante.nome' },
    { text: 'Data do Cadastro', value: 'dataCriacao' },
    { text: 'Situação', value: 'situacaoAdiantamentoCarteira.nome' },
  ];  

  
  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Adiantamento da Carteira', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
 
    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, undefined, '', '', 'EmpresaSolicitante, SituacaoAdiantamentoCarteira').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },  
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  }
 
  Novo(){
    this.item = new AdiantamentoCarteira; 
    this.dialogCadastro = true; 
  }  

  Editar(item){
    this.service.ObterPorId(item.id, "TipoAdiantamentoCarteira").then(
      (res) => {        
        this.item = new AdiantamentoCarteira(res.data);
       // this.item.primeiroMesParcela = new Date(res.data.primeiroMesParcela).yyyymmdd();
        this.dialogCadastro = true;
      }); 
  }  

  mounted(){
   // this.Atualizar();
  }
}
